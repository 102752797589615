import {
  Button,
  Form,
  Input,
  Row,
  Pagination,
  notification,
  DatePicker,
} from "antd";
import "moment/locale/vi"; // Import locale tiếng Việt
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/vi_VN";
import $axios from "../../services/axios";
import _ from "lodash";
import dayjs from "dayjs";

const History = ({ setStep, setLoading }) => {
  const [form] = Form.useForm();
  const [listHistory, setListHistory] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const rangePresets = [
    {
      label: "7 ngày qua",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "14 ngày qua",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "30 ngày qua",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "90 ngày qua",
      value: [dayjs().add(-90, "d"), dayjs()],
    },
  ];

  useEffect(() => {
    getListHistory(currentPage, _.get(rangePresets, "[0].value", []));
  }, []);

  const getListHistory = async (page, dateRange) => {
    const startTime = dayjs(dateRange[0]).format("DD/MM/YYYY") + " 00:00:00";
    const endTime = dayjs(dateRange[1]).format("DD/MM/YYYY") + " 00:00:00";
    setLoading(true);
    try {
      const res = await $axios.$get(
        `/transactions/list?startDate=${startTime}&endDate=${endTime}&page=${currentPage}&pageSize=10`
      );
      setListHistory(res.data.data.data);
      setTotal(res.data.data.totalRecordCount);
    } catch (error) {
      notification.error({
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      setLoading(false);
    }
  };
  const onFinish = async (values) => {
    try {
      await getListHistory(currentPage, values.dateRange);
    } catch (error) {
      notification({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    }
  };
  return (
    <div className="flex items-center justify-center w-full absolute">
      <div className="flex flex-col gap-2 items-center w-[92%]">
        <h2 className="text-white text-[16px] font-semibold mb-4">
          Lịch sử đặt phòng
        </h2>
        <div className="w-full px-5 py-6 rounded-[12px] bg-white">
          <Form
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              dateRange: rangePresets[0].value, // Giá trị mặc định cho `dateRange`
            }}
            className="flex items-start gap-2 m-0"
          >
            <Form.Item
              name={"dateRange"}
              rules={[{ required: true, message: "Vui lòng chọn thời gian!" }]}
              className="flex-1 m-0"
            >
              <DatePicker.RangePicker
                popupClassName="ant-picker-no-left"
                presets={rangePresets}
                format="DD/MM/YYYY"
                className="h-10"
                locale={locale}
                suffixIcon={<img src={"/images/date.svg"} alt="" />}
              />
            </Form.Item>

            <Form.Item className="m-0">
              <Button
                type="primary"
                htmlType="submit"
                className="bg-[#0064D2] h-10 font-bold text-white"
              >
                Tìm kiếm
              </Button>
            </Form.Item>
          </Form>
          {/* Hotline */}
        </div>
        <div className="w-full">
          <div className="mt-2 text-center">
            <span className="font-normal text-white">
              Hotline hỗ trợ khách hàng{" "}
            </span>
            <a
              href="tel:0868320320"
              className="font-bold text-white text-[16px]"
            >
              0876.100.800
            </a>
          </div>
          {total > 0 && <p className="my-4 font-bold text-white">Đặt phòng</p>}
        </div>
        {listHistory.length === 0 && (
          <h2 className="w-full text-primary font-semibold text-[18px] text-center absolute top-[480px] left-1/2 transform -translate-x-1/2 p-4">
            Không tìm thấy lịch sử đặt phòng, vui lòng tìm kiếm lại
          </h2>
        )}
        {listHistory.length > 0 &&
          listHistory.map((item, index) => (
            <div
              key={item.id}
              className="mt-2 w-full p-4 bg-white rounded-[12px] mb-4 "
              style={{
                boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div className="flex flex-col items-start gap-1">
                <p className="text-[12px]">
                  Mã đơn hàng :{" "}
                  <span className="text-orange-600 text-[14px] font-semibold">
                    {item.orderCode}
                  </span>
                </p>
                <p className="text-[12px]">
                  Khách sạn :{" "}
                  <span className="text-primary text-[12px] font-semibold">
                    {item.hotelName}
                  </span>
                </p>
                <p className="text-[12px] flex gap-4">
                  <span className="text-[12px]">
                    {" "}
                    Checkin :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.hotelCheckin}
                    </span>
                  </span>
                  <span className="text-[12px]">
                    {" "}
                    Checkout :{" "}
                    <span className="text-primary text-[12px] font-semibold">
                      {item.hotelCheckout}
                    </span>
                  </span>
                </p>
                <p className="text-[12px]">
                  Điạ chỉ :{" "}
                  <span className="text-primary text-[12px] font-semibold">
                    {item.hotelAddress}
                  </span>
                </p>
              </div>
              <hr className="my-2" />
              <Row className="items-center justify-between">
                <div className="flex items-center w-1/2 gap-2">
                  <div className="flex flex-col gap-1">
                    <p className="text-[10px]">
                      Họ và tên :{" "}
                      <span className="text-primary text-[10px] font-semibold">
                        {item.customerName}
                      </span>
                    </p>
                    <p className="text-[10px]">
                      Số điện thoại :{" "}
                      <span className="text-primary text-[10px] font-semibold">
                        {item.customerPhone}
                      </span>
                    </p>
                    <p className="text-[10px]">
                      Email :{" "}
                      <span className="text-primary text-[10px] font-semibold">
                        {item.customerEmail}
                      </span>
                    </p>
                    <div
                      className={`text-white ${
                        item.paymentStatus === 1 ? "bg-green-600" : "bg-red-600"
                      } rounded-[20px] px-4 py-1 w-fit text-[10px]`}
                    >
                      {item.paymentStatus === 1
                        ? "Đã thanh toán"
                        : "Chưa thanh toán"}
                    </div>
                    {item.paymentStatus === 1 && (
                      <div
                        className={`text-white ${
                          item.providerStatus === 1
                            ? "bg-orange-600"
                            : "bg-red-600"
                        } rounded-[20px] px-4 py-1 w-fit text-[10px]`}
                      >
                        {item.providerStatus === 1
                          ? "Đặt phòng thành công"
                          : "Đặt phòng thất bại"}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col w-1/2 gap-1">
                  <p className="text-[10px]">
                    Loại phòng:{" "}
                    <span className="font-bold text-primary text-[10px] ">
                      {item.roomTypeName}
                    </span>
                  </p>
                  <p className="text-[10px]">
                    Số lượng phòng:{" "}
                    <span className="font-bold text-primary text-[10px] ">
                      {item.numberOfUnits}
                    </span>
                  </p>
                  <p className="text-[10px]">
                    Số lượng khách:{" "}
                    <span className="font-bold text-primary text-[10px] ">
                      {item.hotelTransactionPeople.length}
                    </span>
                  </p>
                  <p className="text-[10px]">
                    Tổng tiền :{" "}
                    <span className="text-primary text-[14px] font-semibold">
                      {item.amount.toLocaleString("vi-VN")} đ
                    </span>
                  </p>
                </div>
              </Row>
            </div>
          ))}

        {total > 0 && (
          <Pagination
            current={currentPage}
            className="mt-2"
            total={total}
            onChange={(page) => {
              setCurrentPage(page);
              getListHistory(page);
            }}
            pageSize={10}
            showSizeChanger={false}
            showQuickJumper={false}
          />
        )}
      </div>
    </div>
  );
};

export default History;
