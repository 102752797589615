import React, { useState, useEffect } from "react";
// import BgSuccess from "../../images/bg-success.svg";
import { Button, notification, Descriptions } from "antd";
import _ from "lodash";
import $axios from "../../services/axios";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";

const PaymentResult = ({ setLoading, loading }) => {
  const location = useLocation();
  const history = useHistory();
  const [statusPayment, setStatusPayment] = useState(true);
  const [bookingHotel, setBookingHotel] = useState(null);

  useEffect(() => {
    handleBooking();
  }, []);

  const handleBooking = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
    const payment_type = queryParams.get("payment_type");
    const reference_number = queryParams.get("reference_number");
    const status = queryParams.get("status");
    const trans_ref_no = queryParams.get("trans_ref_no");
    const website_id = queryParams.get("website_id");
    const signature = queryParams.get("signature");
    const messageVtc = queryParams.get("message");
    setStatusPayment(status === "1");
    try {
      const body = {
        OrderId: reference_number,
        Amount: amount,
        Message: messageVtc,
        Status: Number(status),
        PaymentType: payment_type,
        ReferenceNumber: reference_number,
        TransRefNo: trans_ref_no,
        WebsiteId: website_id,
        Signature: signature,
      };
      await $axios.$post("/payment/paymentHandle", body);
    } catch (error) {
      console.log(error, "error");
      notification.error({
        status: "error",
        message: "Thất bại",
        description: error?.response?.data?.message || "Đã có lỗi xảy ra!",
      });
    } finally {
      if (status === "1") {
        const res = await $axios.$get(
          `/transactions/details?orderCode=${reference_number}`
        );
        setBookingHotel(res.data.data.data);
      }
      setLoading(false);
      setTimeout(() => {
        const { pathname } = location;
        history.replace(pathname);
      }, 2000);
    }
  };

  const items = [
    {
      key: "orderCode",
      label: "Mã đơn hàng",
      children: <>{bookingHotel?.orderCode}</>,
    },
    {
      key: "customer",
      label: "Thông tin liên hệ",
      children: (
        <div>
          <span>Tên khách hàng: {bookingHotel?.customerName}</span>
          <br />
          <span>Email: {bookingHotel?.customerEmail}</span>
          <br />
          <span>Số điện thoại: {bookingHotel?.customerPhone}</span>
        </div>
      ),
    },
    {
      key: "hotel",
      label: "Thông tin khách sạn",
      children: (
        <div>
          <span>Tên khách sạn: {bookingHotel?.hotelName}</span>
          <br />
          <span>Địa chỉ: {bookingHotel?.hotelAddress}</span>
        </div>
      ),
    },
    {
      key: "timeCheck",
      label: "Thời gian Checkin - Checkout",
      children: (
        <div>
          <span>Checkin: {bookingHotel?.hotelCheckin}</span>
          <br />
          <span>Checkout: {bookingHotel?.hotelCheckout}</span>
        </div>
      ),
    },
    {
      key: "room",
      label: "Thông tin phòng",
      children: (
        <div>
          <span>Loại phòng: {bookingHotel?.roomTypeName}</span>
          <br />
          <span>Số lượng phòng: {bookingHotel?.numberOfUnits}</span>
        </div>
      ),
    },
    {
      key: "room",
      label: "Thông tin khách đặt phòng",
      children: (
        <div>
          {bookingHotel?.hotelTransactionPeople?.map((item) => (
            <>
              <br />
              <span>Họ và tên: {item?.fullName}</span>
              <br />
              <span>Phòng số : {item?.roomIndex}</span>
            </>
          ))}
        </div>
      ),
    },
    {
      key: "VAT",
      label: "VAT",
      children: (
        <>
          {bookingHotel?.vatMst ? (
            <>
              Mã số thuế: {bookingHotel?.vatMst}
              <br />
              Họ tên: {bookingHotel?.vatFullName}
              <br />
              Email: {bookingHotel?.vatEmail}
            </>
          ) : (
            "Không"
          )}
        </>
      ),
    },
    {
      key: "amount",
      label: "Tổng tiền",
      children: (
        <div className="text-[16px] text-red-700 font-semibold">
          {bookingHotel?.amount?.toLocaleString("vi-VN")} đ
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col items-center pt-[24px] absolute">
      <div className="w-[90%]">
        <div className="w-full">
          {/* <img src={BgSuccess} alt="" className="w-full" /> */}
          <div className="flex flex-col justify-center gap-4">
            {!loading && (
              <>
                {statusPayment ? (
                  <div className="flex flex-col p-4 bg-white rounded-lg">
                    <div className="text-[24px] text-[#00AB56] font-bold text-center mb-4 uppercase">
                      Thanh toán thành công
                    </div>
                    {!_.isNil(bookingHotel) && (
                      <Descriptions
                        title="Thông tin đặt phòng"
                        bordered
                        items={items}
                      />
                    )}
                  </div>
                ) : (
                  <div className="flex flex-col p-4 bg-white rounded-lg">
                    <div className="text-[24px] text-[#D72F20] font-bold text-center uppercase">
                      Thanh toán thất bại
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className="w-full px-4 mt-4">
          <div className="flex justify-between">
            <Button
              onClick={() => {
                window.location.href = "/booking-taxi";
              }}
              className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#0064D2] border-[#0064D2] text-[15px]"
            >
              <span className="font-semibold">Trang chủ</span>
            </Button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentResult;
