export const BED_TYPE = {
  1: "DoubleBed",
  3: "King Bed",
  5: "Queen Bed",
  2: "Semi-double",
  9: "SingleBed",
  4: "RoundBed",
  6: "SofaBed",
  7: "Tatami",
  10: "Kang",
  11: "SpaceCapsule",
  12: "CapsuleBed",
  13: "WaterBed",
  14: "Bunk Bed",
  15: "Floor Bedding",
  17: "Twin bed",
};

export const MEAL_TYPE = {
  1: "Dinner",
  2: "Lunch",
  3: "Lunch and dinner",
  4: "Breakfast",
  5: "Breakfast and dinner",
  6: "Breakfast and lunch",
  7: "Full Board",
  8: "Half Board",
  9: "All-Inclusive",
  10: "Lunch or Dinner",
  11: "Breakfast + Lunch or Dinner",
};

export const BED_TYPE_VN = {
  1: "Giường đôi",
  3: "Giường King",
  5: "Giường Queen",
  2: "Giường bán đôi",
  9: "Giường đơn",
  4: "Giường tròn",
  6: "Giường sofa",
  7: "Giường Tatami",
  10: "Giường Kang",
  11: "Buồng không gian",
  12: "Giường con nhộng",
  13: "Giường nước",
  14: "Giường tầng",
  15: "Nệm trải sàn",
  17: "Giường đôi nhỏ (Twin bed)",
};

export const MEAL_TYPE_VN = {
  1: "Bữa tối",
  2: "Bữa trưa",
  3: "Bữa trưa và bữa tối",
  4: "Bữa sáng",
  5: "Bữa sáng và bữa tối",
  6: "Bữa sáng và bữa trưa",
  7: "Ăn cả ngày",
  8: "Nửa ngày ăn",
  9: "Ăn trọn gói",
  10: "Bữa trưa hoặc bữa tối",
  11: "Bữa sáng + Bữa trưa hoặc bữa tối",
};
