import React, { createContext } from "react";
import { Divider, Button, Modal } from "antd";
import moment from "moment";
import { MEAL_TYPE_VN, BED_TYPE_VN } from "../../helpers/const";
import _ from "lodash";
const ReachableContext = createContext(null);

const RoomItem = ({ data, setRoomSelected, loading, indexRoom }) => {
  const [modal, contextHolder] = Modal.useModal();

  const openModalCancle = (rate) => {
    const instance = modal.info({
      title: "",
      maskClosable: false,
      closable: false,
      icon: null,
      footer: [
        <div className="w-full text-center">
          <Button
            key="ok"
            type="primary"
            onClick={() => instance.destroy()}
            className="bg-[#0064D2] text-white"
          >
            Đóng
          </Button>
        </div>,
      ],
      content: (
        <>
          <ReachableContext.Consumer>
            {() => (
              <>
                {rate.cancellationPolicies.length === 1 ? (
                  <>
                    <div className="mt-1 pl-3">
                      Huỷ trước{" "}
                      <span className="font-semibold">
                        {moment(rate.cancellationPolicies[0].deadline).format(
                          "HH:mm DD/MM/YYYY"
                        )}{" "}
                      </span>
                      : Miễn phí
                    </div>
                    <div className="pl-3">
                      Huỷ sau{" "}
                      <span className="font-semibold">
                        {" "}
                        {moment(rate.cancellationPolicies[0]?.deadline).format(
                          "HH:mm DD/MM/YYYY"
                        )}{" "}
                        :{" "}
                      </span>
                      <span className="text-red-600 font-semibold">
                        {" "}
                        {rate.cancellationPolicies[0]?.penalty?.toLocaleString(
                          "vi-VN"
                        )}{" "}
                        đ
                      </span>
                    </div>
                  </>
                ) : (
                  <div>
                    {rate.cancellationPolicies.map((can, index) => (
                      <div>
                        {index === 0 && (
                          <div>
                            Huỷ trước{" "}
                            <span className="font-semibold">
                              {moment(can.deadline).format("HH:mm DD/MM/YYYY")}
                            </span>{" "}
                            : Miễn phí
                          </div>
                        )}
                        {index > 0 &&
                          index < rate.cancellationPolicies.length - 1 && (
                            <div>
                              Huỷ từ{" "}
                              <span className="font-semibold">
                                {moment(
                                  rate.cancellationPolicies[index - 1].deadline
                                ).format("HH:mm DD/MM/YYYY")}{" "}
                              </span>
                              đến{" "}
                              <span className="font-semibold">
                                {" "}
                                {moment(can.deadline).format(
                                  "HH:mm DD/MM/YYYY"
                                )}{" "}
                              </span>
                              :
                              <span className="text-red-500">
                                {" "}
                                {rate.cancellationPolicies[
                                  index - 1
                                ]?.penalty.toLocaleString("vi-VN")}
                                đ
                              </span>
                            </div>
                          )}
                        {index === rate.cancellationPolicies.length - 1 && (
                          <div>
                            Huỷ sau{" "}
                            <span className="font-semibold">
                              {" "}
                              {moment(can.deadline).format("HH:mm DD/MM/YYYY")}
                            </span>{" "}
                            :
                            <span className="text-red-500">
                              {can?.penalty.toLocaleString("vi-VN")} đ
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </ReachableContext.Consumer>
          <br />
        </>
      ),
    });
  };

  return (
    <>
      <div className="p-4 bg-white rounded-bl-[12px] rounded-br-[12px] flex flex-col">
        <div className="mb-3">
          <span className="text-primary text-[16px] font-semibold">
            {" "}
            {indexRoom}. {data.roomTypeName}
          </span>
        </div>
        {/* <div className="mb-3">
          <span className="font-semibold">Mã loại phòng</span>:{" "}
          {data.roomTypeCode}
        </div> */}
        {/* <div className="font-semibold mb-2">Các Lưa cụ thể: </div> */}
        <Divider
          variant="dashed"
          dashed
          className="my-2"
          style={{ borderColor: "#0064d2" }}
        />
        {data.rates.map((rate, index) => (
          <div className="px-2 flex flex-col items-center gap-2">
            <div className="flex justify-between w-full">
              <span className="font-semibold">Lựa chọn {index + 1}</span>
              <span className="text-[#00b6f3]">Thông tin phòng</span>
            </div>

            <div className="flex-1 w-full">
              <div className="text-[#4A5568] flex items-center gap-2 mb-2">
                <img src="/images/ic-money.svg" alt="" />
                <span>
                  {" "}
                  {rate.cancellationPolicies.length > 0
                    ? "Hoàn huỷ một phần"
                    : "Không hỗ trợ hoàn huỷ"}
                </span>
                {rate.cancellationPolicies.length > 0 && (
                  <img
                    src="/images/icon-noti.svg"
                    alt=""
                    onClick={() => openModalCancle(rate)}
                  />
                )}
              </div>

              <div className="text-[#4A5568] flex items-center gap-2 mb-2">
                <img src="/images/ic-meal.svg" alt="" />
                <span>
                  {" "}
                  {rate.mealType > 0
                    ? `Bao gồm ${_.toLower(MEAL_TYPE_VN[rate.mealType])}`
                    : "Không bao gồm bữa ăn"}
                </span>
              </div>

              {rate.bedType.beds.length > 0 && (
                <div className="text-[#4A5568] flex items-center gap-2 mb-2">
                  <img src="/images/ic-bed.svg" alt="" />
                  <span>
                    {" "}
                    {rate.bedType.beds.length === 1
                      ? `${rate?.bedType?.beds[0]?.count} ${_.toLower(
                          BED_TYPE_VN[rate?.bedType?.beds[0]?.code]
                        )}`
                      : `${rate.bedType.beds
                          .map((bed, index) => {
                            let relation = "";
                            if (index < rate.bedType.beds.length - 1) {
                              relation =
                                rate.bedType.relation === "AND" ? "và" : "hoặc";
                            }
                            return (
                              bed.count +
                              " " +
                              _.toLower(BED_TYPE_VN[bed.code]) +
                              " " +
                              relation
                            );
                          })
                          .join(" ")}`}
                  </span>
                </div>
              )}

              <div className="text-[#4A5568] flex items-center gap-2 mb-2">
                <img
                  className="w-[18px] h-[18px]"
                  src="https://storage.googleapis.com/tripi-assets/images/hotels/amenities/doanhnhanh/phong-hop.png"
                  alt=""
                />
                <span>Đặt tối đa: {rate.bookingLimit} phòng</span>
              </div>

              <div className="text-[#ed8936] flex items-center gap-2 mb-2">
                <img src="/images/ic-confirm.svg" alt="" />
                <span>Xác nhận ngay</span>
              </div>

              <div className="text-[#ff3366] flex items-center gap-2 mb-2">
                <img src="/images/ic-vat.svg" alt="" />
                <span>
                  An tâm đặt phòng, Ipass hỗ trợ xuất hoá đơn nhanh chóng, tiết
                  kiệm thời gian cho bạn.
                </span>
              </div>
              {/* <div className="mt-2">
                Tổng tiền trước thuế :{" "}
                <span className="text-[16px] text-primary font-bold">
                  {rate?.rateBeforeTaxTotalNew?.toLocaleString("vi-VN")} đ
                </span>
              </div> */}

              {/* <span className="font-semibold">Loại giường: {BED_TYPE[rate.]}</span> */}
            </div>
            <div className="flex flex-col w-full">
              <div className="flex justify-between items-center w-full">
                <span className="text-[12px] italic">
                  Được cung cấp bởi đối tác của chúng tôi
                </span>
                <span className="text-[18px] text-primary font-bold">
                  {rate?.rateAfterTaxTotalNew?.toLocaleString("vi-VN")} đ
                </span>
              </div>

              <Button
                color="default"
                onClick={() => {
                  setRoomSelected(index, data);
                }}
                loading={loading}
                type="primary"
                className=" bg-[#0064D2] text-white mt-2 w-[140px] ml-auto h-[36px]"
              >
                <span className="font-semibold">Đặt ngay</span>
              </Button>
            </div>
            <Divider
              variant="dashed"
              dashed
              className="my-2"
              style={{ borderColor: "#0064d2" }}
            />
          </div>
        ))}
      </div>
      {contextHolder}

      {/* <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
          <img
            src={data.thumbnail.origin}
            alt=""
            className="absolute top-0 left-0 w-full h-full object-cover"
          />
        </div>
        <div className="p-4 bg-white rounded-bl-[12px] rounded-br-[12px] flex flex-col">
          <h3 className="text-[18px] mt-3 font-bold text-[#0064D2]">
            {data.name}
          </h3>
          <ul className="mt-4">
            <li className="flex items-center mb-2">
              <img
                src="/images/dientich.png"
                alt=""
                className="w-[20px] mt-1"
              />
              <span className="text-[13px]">
                Diện tích: {data?.area?.squareMeter} m{" "}
                <sup className="text-[10px]">2</sup>
              </span>
            </li>
            <li className="flex items-center">
              <img src="/images/people.png" alt="" className="w-[20px] mt-1" />
              <span className="text-[13px]">
                Sức chứa: {data?.occupancy?.standardGuests} người
              </span>
            </li>
          </ul>
          <Button
            color="default"
            onClick={() => {
              setRoomlSelected(data);
            }}
            type="primary"
            className=" bg-[#0064D2] text-white h-9 mt-3 w-[120px] ml-auto"
          >
            <span className="font-semibold">Đặt ngay</span>
          </Button>
        </div> */}
    </>
  );
};

export default RoomItem;
