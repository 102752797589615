import React from "react";
import { Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faEye } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";

const HotelItem = ({ setOpenChooseHotel, data, setHotelSelected }) => {
  return (
    <div
      className="mt-2 rounded-[12px] my-4 w-full flex flex-col mb-[20px]"
      style={{
        boxShadow: "0px 4px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
        <img
          src={data.thumbnail.origin}
          alt=""
          className="rounded-tl-[12px] rounded-tr-[12px] absolute top-0 left-0 w-full h-full object-cover"
        />
      </div>
      <div className="p-4 bg-white rounded-bl-[12px] rounded-br-[12px] flex flex-col">
        <div className="flex gap-3 items-center">
          <span className="uppercase text-[#191919] text-[12px] font-semibold">
            Khách sạn
          </span>
          <div className="flex gap-1 items-center">
            {_.range(1, data?.rating?.starNumber + 1).map((s) => (
              <FontAwesomeIcon
                key={s}
                icon={faStar}
                color="#f6d738"
                style={{
                  fontSize: 18,
                }}
              />
            ))}
          </div>
        </div>
        <h3 className="text-[18px] mt-3 font-bold text-[#15284a]">
          {data.name}
        </h3>
        <div className="text-[#9aa2b2] text-[13px] my-2">
          {data.address.address}
        </div>
        <div className="text-[#0064D2] text-[10px] text-left flex gap-2">
          <span>Checkin: {data?.checkin?.beginTime}</span> <span> - </span>
          <span>Checkout: {data?.checkout?.time}</span>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description?.slice(0, 100) + "...",
          }}
          className="text-gray-700 duration-300 my-1"
        />
        <div className=" flex gap-2 items-end w-full my-1">
          <span className="text-[18px] text-red-700 font-bold text-right ml-auto">
            <span className="text-[14px]">Giá từ</span>{" "}
            {data?.minPrice?.toLocaleString("vi-VN")} đ /{" "}
            {data.nights > 1 ? data.nights : ""} đêm
          </span>
        </div>
        <Button
          color="default"
          onClick={() => {
            setOpenChooseHotel(true);
            setHotelSelected(data);
          }}
          type="primary"
          className=" bg-[#0064D2] text-white h-12 mt-3 w-[160px] ml-auto"
        >
          <FontAwesomeIcon icon={faEye} color="white" />
          <span className="uppercase font-bold">Xem phòng</span>
        </Button>
      </div>
    </div>
  );
};

export default HotelItem;
