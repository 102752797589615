import _ from "lodash";

export const convertMinutesToHoursAndMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h${remainingMinutes}'`;
};

export const moveToFirst = (arr, key, value) => {
  // Tìm và xóa phần tử cần chuyển
  const removedElement = _.remove(arr, (item) => item[key] === value);
  // Thêm phần tử đã xóa vào đầu mảng
  if (removedElement.length) {
    arr.unshift(removedElement[0]);
  }
  return arr;
};

export const removeDiacritics = (str) => {
  if (!str) return ""; // Xử lý trường hợp chuỗi null hoặc undefined
  return str
    .normalize("NFD") // Phân tách ký tự có dấu
    .replace(/[\u0300-\u036f]/g, "") // Loại bỏ dấu
    .replace(/đ/g, "d") // Thay thế "đ" thành "d"
    .replace(/Đ/g, "D"); // Thay thế "Đ" thành "D"
};
