import React from "react";
import { Calendar } from "react-calendar";
import "./CalendarCustom.scss";
import { Drawer } from "antd";

const CalendarCustom = ({
  openCalendar,
  typeCalendar,
  setValue,
  valueCalendar,
  setOpenCalendar,
  minDate,
}) => {
  const drawerStyles = {
    header: {
      display: "none",
      borderBottom: `1px solid red`,
    },
    body: {
      padding: 0,
    },
  };

  const onClose = () => {
    setOpenCalendar(false);
  };

  return (
    <Drawer
      classNames="p-0"
      title="Basic Drawer"
      placement="bottom"
      closable={false}
      onClose={onClose}
      open={openCalendar}
      styles={drawerStyles}
    >
      <div className="z-50 bg-[#3884D8] calendar-custom">
        <div className="bg-white flex items-center justify-center relative py-5 px-2">
          <div className="font-semibold text-[20px]">
            {typeCalendar === "departDate"
              ? "Ngày nhận phòng"
              : "Ngày trả phòng"}
          </div>
        </div>
        <Calendar
          onChange={setValue}
          onClose
          value={valueCalendar}
          nextLabel={<img src={"/images/nextMonth.svg"} alt="" />}
          prevLabel={<img src={"/images/backMonth.svg"} alt="" />}
          next2Label={<img src={"/images/nextYear.svg"} alt="" />}
          prev2Label={<img src={"/images/backYear.svg"} alt="" />}
          minDate={minDate}
          locale="vi"
        />
      </div>
    </Drawer>
  );
};

export default CalendarCustom;
